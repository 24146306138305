@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Kanit";
  src: url("./fonts/Kanit/Kanit-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Kanit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Kanit";
}
