html {
  font-size: 16px;
}

@media (max-width: 640px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 641px) and (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 2300px) {
  html {
    font-size: 18px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flow-animation-right {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(20px);
  }
}

.particles {
  animation: flow-animation-right 1s infinite linear;
  background-repeat: repeat-x;
  background-size: 20px 10px;
  height: 10px;
}

.particles.particles-right {
  animation-direction: normal;
}

.particles.particles-left {
  animation-direction: reverse;
}

@media screen and (max-width: 992px) and (orientation: portrait) {
  .portrait-to-landscape-absolute {
    transform: rotate(90deg);
  }
  .portrait-fullscreen-rotated-absolute {
    min-width: 98vh !important;
    height: 98vw !important;
  }

  .portrait-to-landscape {
    transform: rotate(90deg);
    transform-origin: 45vw 45vw;
    position: absolute;
    left: 2.5vw;
  }
  .portrait-fullscreen-rotated {
    height: 90vw !important;
    width: 80vh !important;
  }
  .portrait-fullscreen-dummy {
    height: 80vh !important;
  }
}

.hover-menu-parent {
  cursor: pointer;
}

.hover-menu-child {
  position: fixed;
  transition-duration: 0.2s;
  transform-origin: left top;
  transform: scaleY(0) translateX(calc(-50% + 30px));
}

.hover-menu-parent:hover > .hover-menu-child {
  transform: scaleY(1) translateX(calc(-50% + 30px));
}
